(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/core/flash/assets/javascripts/flash.js') >= 0) return;  svs.modules.push('/components/core/flash/assets/javascripts/flash.js');

svs = svs || {};

(function(svs) {
  'use strict';

  svs.core = svs.core || {};
  svs.core.flash = function(type, message, callback) {
    svs.core.deviceSession.get('flash', undefined, function(flash) {
      var messages = flash || {};
      if (type && message) {
        (messages[type] = messages[type] || []).push(message);

        svs.core.deviceSession.set('flash', undefined, messages, callback);
      } else if (type) {
        var arr = messages[type];

        if (arr) {
          delete messages[type];
          svs.core.deviceSession.set('flash', undefined, messages, callback);
        }
      } else {
        svs.core.deviceSession.set('flash', undefined, {}, callback);
      }

      return undefined;
    });
  };

  svs.core.flash.flush = function(callback) {
    svs.core.deviceSession.get('flash', '', function(messages) {
      if (messages) {
        svs.core.deviceSession.remove('flash', '', function() {
          callback(messages);
        });
      }
    });
  };
})(svs);


 })(window);